// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();
global.$ = require("jquery");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix");
require("@rails/actiontext");
import "stylesheets/main.css";

function toggleMenu(e) {
    if (e.target.id === "js-dropdown-menu") {
        $("#sortboxmenu").toggleClass("hidden");
        $("i#js-dropdown-menu").toggleClass("fa-bars");
        $("i#js-dropdown-menu").toggleClass("fa-times");
    }
}

function toggleAccordion() {
    var accordion = document.getElementsByClassName("js-accordion");
    var i;

    for (i = 0; i < accordion.length; i++) {
        accordion[i].addEventListener("click", function () {
            this.classList.toggle("active");

            var panel = this.nextElementSibling;

            if (panel.style.display === "block") {
                panel.style.display = "none";
            } else {
                panel.style.display = "block";
            }
        });
    }
}

function calendarTicketField() {
    const $ticketContainer = Array.from(document.querySelectorAll(".js-ticket-container"));
    [...$ticketContainer].forEach((el) => {
        const $radio = Array.from(el.querySelectorAll('input[type="radio"]'));
        const disabledDiv = Array.from(el.querySelectorAll(".disabled-div"));
        const submitBtn = el.querySelectorAll('button[type="Submit"]')[0];
        [...$radio].forEach((r) => {
            r.addEventListener("change", (e) => {
                if (e.target.checked) {
                    [...disabledDiv].forEach((d) => {
                        d.classList.remove("disabled-div");
                        let input = d.getElementsByTagName("input")[0];
                        input.removeAttribute("disabled");
                        submitBtn.removeAttribute("disabled");
                    });
                }
            });
        });
    });
}

function show_events(date) {
    var searchParams = new URLSearchParams(location.search);
    searchParams.set("selected_date", date);
    window.location.href =
        window.location.pathname + "?" + searchParams.toString();
}

function companionField(e) {
    if (e.target.name === "purchase[quantity]") {
        if (e.target.value > 1) {
            $("input.js-companion-field").attr("required", true);
            $(".js-companion-field-group").removeClass("hidden");
        } else {
            $("input.js-companion-field").removeAttr("required");
            $(".js-companion-field-group").addClass("hidden");
        }
    }
}

document.addEventListener("DOMContentLoaded", function () {
    const searchParams = new URLSearchParams(location.search);
    const selectedDate = searchParams.get("selected_date");
    if (selectedDate) {
        const $el = document.querySelector(`[data-date="${selectedDate}"]`);
        if ($el) {
            $el.parentElement.classList.add("is-active");
        }
    }

    const $days = document.querySelectorAll(".js-day");
    if ($days.length) {
        var i;
        // [...$days].forEach(($el) => {
        //     $el.addEventListener("click", (e) => {
        //         const { date } = e.currentTarget.dataset;
        //         show_events(date);
        //     });
        // });
        for (i = 0; i < $days.length; i++) {
            $days[i].addEventListener("click", (e) => {
                const { date } = e.currentTarget.dataset;
                show_events(date);
            });
        };
    }
});

//For prototype
function toggleTheaterMode(e) {
    if (e.target.id === "js-theater-btn") {
        $(".streaming-container").toggleClass("theater-mode");
        $("span#js-theater-btn").toggleClass("d-none");
    }
}

document.addEventListener("DOMContentLoaded", () => {
    toggleAccordion();
    calendarTicketField();
});

document.addEventListener("click", toggleMenu);
document.addEventListener("click", toggleTheaterMode);
document.addEventListener("change", companionField);
